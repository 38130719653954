/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AppProjectsOilSchemasSchemasUpdateOut,
  BlendOut,
  BlendsOut,
  CallbackRunResponse,
  HTTPValidationError,
  ProfilerMode,
  ProjectTypeEnum,
  RJSFSchemas,
  SampleOut,
  SamplesOut
} from '../models/index';
import {
  AppProjectsOilSchemasSchemasUpdateOutFromJSON,
  AppProjectsOilSchemasSchemasUpdateOutToJSON,
  BlendOutFromJSON,
  BlendOutToJSON,
  BlendsOutFromJSON,
  BlendsOutToJSON,
  CallbackRunResponseFromJSON,
  CallbackRunResponseToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ProfilerModeFromJSON,
  ProfilerModeToJSON,
  ProjectTypeEnumFromJSON,
  ProjectTypeEnumToJSON,
  RJSFSchemasFromJSON,
  RJSFSchemasToJSON,
  SampleOutFromJSON,
  SampleOutToJSON,
  SamplesOutFromJSON,
  SamplesOutToJSON
} from '../models/index';

export interface OilCreateBlendRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilCreateSampleRequest {
  checkpointUuid: string;
  projectUuid: string;
  idempotencyKey: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilDeleteBlendRequest {
  blendUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilDeleteSampleRequest {
  sampleUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilGetBlendRequest {
  blendUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilGetBlendsRequest {
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilGetSampleRequest {
  sampleUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilGetSamplesRequest {
  checkpointUuid: string;
  projectUuid: string;
  type?: null;
  xProfilerMode?: ProfilerMode;
}

export interface OilRunBlendCallbackRequest {
  blendUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilRunSampleCallbackRequest {
  sampleUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilUpdateBlendRequest {
  blendUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

export interface OilUpdateSampleRequest {
  sampleUuid: string;
  checkpointUuid: string;
  projectUuid: string;
  body: object;
  type?: ProjectTypeEnum | null;
  xProfilerMode?: ProfilerMode;
}

/**
 *
 */
export class OilApi extends runtime.BaseAPI {
  /**
   * Create Blend
   */
  async oilCreateBlendRaw(
    requestParameters: OilCreateBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BlendOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilCreateBlend().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilCreateBlend().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling oilCreateBlend().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BlendOutFromJSON(jsonValue));
  }

  /**
   * Create Blend
   */
  async oilCreateBlend(
    requestParameters: OilCreateBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BlendOut> {
    const response = await this.oilCreateBlendRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create Sample
   */
  async oilCreateSampleRaw(
    requestParameters: OilCreateSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SampleOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilCreateSample().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilCreateSample().'
      );
    }

    if (requestParameters['idempotencyKey'] == null) {
      throw new runtime.RequiredError(
        'idempotencyKey',
        'Required parameter "idempotencyKey" was null or undefined when calling oilCreateSample().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['idempotencyKey'] != null) {
      headerParameters['idempotency-key'] = String(requestParameters['idempotencyKey']);
    }

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SampleOutFromJSON(jsonValue));
  }

  /**
   * Create Sample
   */
  async oilCreateSample(
    requestParameters: OilCreateSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SampleOut> {
    const response = await this.oilCreateSampleRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete Blend
   */
  async oilDeleteBlendRaw(
    requestParameters: OilDeleteBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['blendUuid'] == null) {
      throw new runtime.RequiredError(
        'blendUuid',
        'Required parameter "blendUuid" was null or undefined when calling oilDeleteBlend().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilDeleteBlend().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilDeleteBlend().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends/{blend_uuid}`
          .replace(`{${'blend_uuid'}}`, encodeURIComponent(String(requestParameters['blendUuid'])))
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Blend
   */
  async oilDeleteBlend(
    requestParameters: OilDeleteBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.oilDeleteBlendRaw(requestParameters, initOverrides);
  }

  /**
   * Delete Sample
   */
  async oilDeleteSampleRaw(
    requestParameters: OilDeleteSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['sampleUuid'] == null) {
      throw new runtime.RequiredError(
        'sampleUuid',
        'Required parameter "sampleUuid" was null or undefined when calling oilDeleteSample().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilDeleteSample().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilDeleteSample().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples/{sample_uuid}`
          .replace(
            `{${'sample_uuid'}}`,
            encodeURIComponent(String(requestParameters['sampleUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete Sample
   */
  async oilDeleteSample(
    requestParameters: OilDeleteSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.oilDeleteSampleRaw(requestParameters, initOverrides);
  }

  /**
   * Get Blend
   */
  async oilGetBlendRaw(
    requestParameters: OilGetBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['blendUuid'] == null) {
      throw new runtime.RequiredError(
        'blendUuid',
        'Required parameter "blendUuid" was null or undefined when calling oilGetBlend().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilGetBlend().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilGetBlend().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends/{blend_uuid}`
          .replace(`{${'blend_uuid'}}`, encodeURIComponent(String(requestParameters['blendUuid'])))
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Blend
   */
  async oilGetBlend(
    requestParameters: OilGetBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.oilGetBlendRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Blends
   */
  async oilGetBlendsRaw(
    requestParameters: OilGetBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BlendsOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilGetBlends().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilGetBlends().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BlendsOutFromJSON(jsonValue));
  }

  /**
   * Get Blends
   */
  async oilGetBlends(
    requestParameters: OilGetBlendsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BlendsOut> {
    const response = await this.oilGetBlendsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Sample
   */
  async oilGetSampleRaw(
    requestParameters: OilGetSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<{[key: string]: RJSFSchemas}>> {
    if (requestParameters['sampleUuid'] == null) {
      throw new runtime.RequiredError(
        'sampleUuid',
        'Required parameter "sampleUuid" was null or undefined when calling oilGetSample().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilGetSample().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilGetSample().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples/{sample_uuid}`
          .replace(
            `{${'sample_uuid'}}`,
            encodeURIComponent(String(requestParameters['sampleUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      runtime.mapValues(jsonValue, RJSFSchemasFromJSON)
    );
  }

  /**
   * Get Sample
   */
  async oilGetSample(
    requestParameters: OilGetSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<{[key: string]: RJSFSchemas}> {
    const response = await this.oilGetSampleRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Samples
   */
  async oilGetSamplesRaw(
    requestParameters: OilGetSamplesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SamplesOut>> {
    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilGetSamples().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilGetSamples().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples`
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => SamplesOutFromJSON(jsonValue));
  }

  /**
   * Get Samples
   */
  async oilGetSamples(
    requestParameters: OilGetSamplesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SamplesOut> {
    const response = await this.oilGetSamplesRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Run Blend Callback
   */
  async oilRunBlendCallbackRaw(
    requestParameters: OilRunBlendCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['blendUuid'] == null) {
      throw new runtime.RequiredError(
        'blendUuid',
        'Required parameter "blendUuid" was null or undefined when calling oilRunBlendCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilRunBlendCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilRunBlendCallback().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends/{blend_uuid}/run_callback`
          .replace(`{${'blend_uuid'}}`, encodeURIComponent(String(requestParameters['blendUuid'])))
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Blend Callback
   */
  async oilRunBlendCallback(
    requestParameters: OilRunBlendCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.oilRunBlendCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Run Sample Callback
   */
  async oilRunSampleCallbackRaw(
    requestParameters: OilRunSampleCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CallbackRunResponse>> {
    if (requestParameters['sampleUuid'] == null) {
      throw new runtime.RequiredError(
        'sampleUuid',
        'Required parameter "sampleUuid" was null or undefined when calling oilRunSampleCallback().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilRunSampleCallback().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilRunSampleCallback().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples/{sample_uuid}/run_callback`
          .replace(
            `{${'sample_uuid'}}`,
            encodeURIComponent(String(requestParameters['sampleUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CallbackRunResponseFromJSON(jsonValue)
    );
  }

  /**
   * Run Sample Callback
   */
  async oilRunSampleCallback(
    requestParameters: OilRunSampleCallbackRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CallbackRunResponse> {
    const response = await this.oilRunSampleCallbackRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Blend
   */
  async oilUpdateBlendRaw(
    requestParameters: OilUpdateBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppProjectsOilSchemasSchemasUpdateOut>> {
    if (requestParameters['blendUuid'] == null) {
      throw new runtime.RequiredError(
        'blendUuid',
        'Required parameter "blendUuid" was null or undefined when calling oilUpdateBlend().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilUpdateBlend().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilUpdateBlend().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling oilUpdateBlend().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/blends/{blend_uuid}`
          .replace(`{${'blend_uuid'}}`, encodeURIComponent(String(requestParameters['blendUuid'])))
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppProjectsOilSchemasSchemasUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Blend
   */
  async oilUpdateBlend(
    requestParameters: OilUpdateBlendRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppProjectsOilSchemasSchemasUpdateOut> {
    const response = await this.oilUpdateBlendRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update Sample
   */
  async oilUpdateSampleRaw(
    requestParameters: OilUpdateSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AppProjectsOilSchemasSchemasUpdateOut>> {
    if (requestParameters['sampleUuid'] == null) {
      throw new runtime.RequiredError(
        'sampleUuid',
        'Required parameter "sampleUuid" was null or undefined when calling oilUpdateSample().'
      );
    }

    if (requestParameters['checkpointUuid'] == null) {
      throw new runtime.RequiredError(
        'checkpointUuid',
        'Required parameter "checkpointUuid" was null or undefined when calling oilUpdateSample().'
      );
    }

    if (requestParameters['projectUuid'] == null) {
      throw new runtime.RequiredError(
        'projectUuid',
        'Required parameter "projectUuid" was null or undefined when calling oilUpdateSample().'
      );
    }

    if (requestParameters['body'] == null) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter "body" was null or undefined when calling oilUpdateSample().'
      );
    }

    const queryParameters: any = {};

    if (requestParameters['type'] != null) {
      queryParameters['type_'] = requestParameters['type'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (requestParameters['xProfilerMode'] != null) {
      headerParameters['X-Profiler-Mode'] = String(requestParameters['xProfilerMode']);
    }

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken('OpenIdConnect', []);
    }

    const response = await this.request(
      {
        path: `/api/public/v1/projects/{project_uuid}/checkpoints/{checkpoint_uuid}/samples/{sample_uuid}`
          .replace(
            `{${'sample_uuid'}}`,
            encodeURIComponent(String(requestParameters['sampleUuid']))
          )
          .replace(
            `{${'checkpoint_uuid'}}`,
            encodeURIComponent(String(requestParameters['checkpointUuid']))
          )
          .replace(
            `{${'project_uuid'}}`,
            encodeURIComponent(String(requestParameters['projectUuid']))
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters['body'] as any
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AppProjectsOilSchemasSchemasUpdateOutFromJSON(jsonValue)
    );
  }

  /**
   * Update Sample
   */
  async oilUpdateSample(
    requestParameters: OilUpdateSampleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AppProjectsOilSchemasSchemasUpdateOut> {
    const response = await this.oilUpdateSampleRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
