import {FC, PropsWithChildren, ReactElement, useRef} from 'react';
import {Box, Center, Grid, GridItem} from '@chakra-ui/react';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {Loader, useResize} from '@progress-fe/ui-kit';

import {useStore} from 'core';
import {ProjectOut} from 'api';
import {ROUTES} from 'core/constants';
import {getProjectUrl} from 'core/utils';
import {TProjectBaseModel} from 'core/models';
import {ProjectJsFormConfigContext} from 'core/context';

import {CheckpointBar, ProjectMainBar} from './components';

export interface IProjectLayoutProps extends PropsWithChildren {
  project: TProjectBaseModel;
  projectActions?: ReactElement;
  isRunDisabled?: boolean;
  onRunProject: () => Promise<void>;
  onRenameProject: (projectUuid: string, newName: string) => Promise<boolean>;
  onRestoreProject: (project: ProjectOut) => Promise<boolean>;
  onDeleteProject: (project: ProjectOut) => Promise<boolean>;
}

const ProjectLayoutFC: FC<IProjectLayoutProps> = ({
  project,
  isRunDisabled,
  projectActions,
  onRunProject,
  onRenameProject,
  onRestoreProject,
  onDeleteProject,
  children
}) => {
  const {authStore} = useStore();
  const {projectInfo, journal} = project;

  const ref = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  useResize(ref, () => journal.close());

  const handleOpenCheckpoint = (checkpointUuid: string) => {
    if (projectInfo) {
      navigate(getProjectUrl(projectInfo.type, projectInfo.uuid, checkpointUuid));
    }
  };

  const handleCreateCheckpoint = async () => {
    const checkpointUuid = await project.createCheckpoint();
    if (projectInfo && checkpointUuid) {
      navigate(getProjectUrl(projectInfo.type, projectInfo.uuid, checkpointUuid));
    }
  };

  const handleDeleteCheckpoint = async (checkpointUuid: string) => {
    const isDone = await project.deleteCheckpoint(checkpointUuid);
    const isCurrent = checkpointUuid === project.checkpointUuid;
    if (isDone && isCurrent) {
      const nextCheckpoint = project.checkpoints.find((c) => c.uuid !== checkpointUuid);
      if (projectInfo && nextCheckpoint) {
        navigate(getProjectUrl(projectInfo.type, projectInfo.uuid, nextCheckpoint.uuid));
      }
    } else if (isDone) {
      await project.refreshProject();
    }
  };

  const handleRenameProject = async (newName: string) => {
    if (projectInfo) {
      const isDone = await onRenameProject(projectInfo.uuid, newName);
      if (isDone) {
        project.refreshProject().then();
      }
    }
  };

  const handleRestoreProject = async () => {
    if (projectInfo) {
      const isDone = await onRestoreProject(projectInfo);
      if (isDone) {
        project.refreshProject().then();
      }
    }
  };

  const handleDeleteProject = async () => {
    if (projectInfo) {
      const isDone = await onDeleteProject(projectInfo);
      if (isDone && projectInfo.deleted) {
        navigate(ROUTES.MyProjects, {replace: true});
      } else if (isDone && !projectInfo.deleted) {
        project.refreshProject().then();
      }
    }
  };

  if (project.isLoading || project.isCheckpointChanging || !projectInfo) {
    return (
      <Center h="100vh" bg="darkWhite">
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      {!!projectInfo && authStore.user && (
        <ProjectMainBar
          projectInfo={projectInfo}
          currentUser={authStore.user}
          runStatus={project.runStatus}
          isRunning={project.isRunning}
          isRunDisabled={isRunDisabled}
          lastSaveDate={project.checkpointLastSaving}
          additionalActions={projectActions}
          onSignOut={authStore.signOut}
          onRunCalculation={onRunProject}
          onRestore={handleRestoreProject}
          onRename={handleRenameProject}
          onDelete={handleDeleteProject}
          onToggleJournal={project.journal.toggleOpen}
          onSave={() => {}}
        />
      )}

      {project.isRunning && <Loader fullScreen />}

      <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'48px 1fr'}>
        <GridItem bg="bg" borderRight="1px" borderColor="border" zIndex={1}>
          <CheckpointBar
            isCreating={project.isCheckpointCreating}
            isDeleting={project.isCheckpointDeleting}
            activeUuid={project.checkpointUuid}
            checkpoints={projectInfo.checkpoints.map((c) => ({uuid: c.uuid, name: c.name}))}
            onRename={project.renameCheckpoint}
            onCreate={handleCreateCheckpoint}
            onSwitch={handleOpenCheckpoint}
            onDelete={handleDeleteCheckpoint}
          />
        </GridItem>
        <GridItem ref={ref}>
          <ProjectJsFormConfigContext>{children}</ProjectJsFormConfigContext>
        </GridItem>
      </Grid>
    </Box>
  );
};

export const ProjectLayout = observer(ProjectLayoutFC);
