import {FC, useEffect, useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Grid, GridItem} from '@chakra-ui/react';
import {FlexDivider} from '@progress-fe/ui-kit';
import {Rnd} from 'react-rnd';

import {EJournalItemType} from 'core/enums';
import {IJournalPoint} from 'core/interfaces';

import {JournalPointList, JournalPointItems, JournalTabs} from './components';

interface IProps {
  journalPoint: IJournalPoint[];
  onClose: () => void;
}

const HEADER_HEIGHT = 48;
const FILTER_HEIGHT = 34;
const DIVIDER_HEIGHT = 8;
const JOURNAL_HEIGHT_MIN = 150;
const JOURNAL_HEIGHT_DEFAULT = 250;

const JournalViewerFC: FC<IProps> = ({journalPoint, onClose}) => {
  const [logsHeight, setLogsHeight] = useState(JOURNAL_HEIGHT_DEFAULT);

  const [activePoint, setActivePoint] = useState<Date | null>();
  const [filterType, setFilterType] = useState<EJournalItemType | null>(null);

  useEffect(() => {
    if (journalPoint.length) {
      setActivePoint(journalPoint[0].runAt);
    }
  }, [journalPoint, journalPoint.length]);

  const activeLogItems = useMemo(() => {
    const logItems = journalPoint.find((p) => p.runAt === activePoint)?.items || [];
    return filterType ? logItems.filter((l) => l.type === filterType) : logItems;
  }, [activePoint, filterType, journalPoint]);

  return (
    <Rnd
      style={{zIndex: 2}}
      disableDragging={true}
      enableResizing={{
        top: true,
        left: false,
        right: false,
        bottom: false,
        topLeft: false,
        topRight: false,
        bottomLeft: false,
        bottomRight: false
      }}
      minHeight={JOURNAL_HEIGHT_MIN}
      maxHeight={document.body.offsetHeight - HEADER_HEIGHT}
      default={{
        x: 0,
        y: document.body.offsetHeight - HEADER_HEIGHT - logsHeight,
        width: '100%',
        height: logsHeight
      }}
      onResize={(_, __, ref) => {
        setLogsHeight(ref.offsetHeight);
      }}
    >
      <Box h={`${logsHeight}px`} overflowY={'hidden'} bg="darkWhite">
        <FlexDivider withDots sx={{bg: 'bg'}} />

        <Grid gridTemplateColumns={'200px 1fr'}>
          <GridItem borderRight={'1px solid'} borderColor={'border'}>
            <Box overflowY={'auto'} h={`${logsHeight - 8}px`}>
              {!!activePoint && (
                <JournalPointList
                  points={journalPoint}
                  activePoint={activePoint}
                  onSelectPoint={setActivePoint}
                />
              )}
            </Box>
          </GridItem>
          <GridItem>
            <JournalTabs
              filterType={filterType}
              onSelectFilterType={setFilterType}
              onClose={onClose}
            />
            <Box overflowY={'auto'} h={`${logsHeight - DIVIDER_HEIGHT - FILTER_HEIGHT}px`}>
              <JournalPointItems items={activeLogItems} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Rnd>
  );
};

export const JournalViewer = observer(JournalViewerFC);
