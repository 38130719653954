import {applySnapshot, cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {LocalStorageHelper} from '@progress-fe/core';
import {IElementErrorMessage} from '@progress-fe/ui-kit';
import {v4 as uuidv4} from 'uuid';

import {EComputeStatusCode} from 'core/enums';
import {RequestModel, ResetModel, TUiStateSnapshotIn} from 'core/models';
import {PROJECT_LIST} from 'core/mocks/projects/projects.mocks';
import {
  ProjectOut,
  CheckpointOut,
  ProjectsApi,
  CheckpointsApi,
  ResponseStatusCheckpointOut,
  CalculationTaskRunStatus
} from 'api';

import {UiState, Journal} from './models';

const isValidUiState = (data: unknown): data is TUiStateSnapshotIn => {
  return UiState.is(data);
};

const UI_STATE_STORAGE = 'progress:checkpoint-ui-state';

const ProjectBase = types
  .compose(
    ResetModel,
    types.model('ProjectBase', {
      isRunning: false,
      isLoading: false,

      checkpointUuid: '',
      checkpointLastSaving: types.maybeNull(types.Date),

      journal: types.optional(Journal, {}),
      uiState: types.optional(UiState, {}),
      projectInfo: types.maybeNull(types.frozen<ProjectOut>()),

      runStatus: types.maybeNull(types.enumeration(Object.values(EComputeStatusCode))),
      errors: types.optional(types.array(types.frozen<IElementErrorMessage>()), []),

      checkpointCreateRequest: types.optional(RequestModel, {}),
      checkpointChangeRequest: types.optional(RequestModel, {}),
      checkpointDeleteRequest: types.optional(RequestModel, {}),
      fetchCheckpointRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {}),
      journalRequest: types.optional(RequestModel, {}),
      runCallbackRequest: types.optional(RequestModel, {}),
      runRequest: types.optional(RequestModel, {})
    })
  )
  .volatile<{
    _lastSaveInterval: NodeJS.Timeout | null;
  }>(() => ({
    _lastSaveInterval: null
  }))
  .views((self) => ({
    get _uiStateStorage(): LocalStorageHelper<TUiStateSnapshotIn> {
      const name = `${UI_STATE_STORAGE}_${self.checkpointUuid}`;
      return new LocalStorageHelper(name, isValidUiState);
    }
  }))
  .actions((self) => ({
    _load: flow(function* (projectUuid: string) {
      const mockProject = PROJECT_LIST.find((p) => p.uuid === projectUuid);
      if (mockProject) {
        return mockProject;
      }

      const response: ProjectOut = yield self.fetchRequest.send(
        ProjectsApi.projectsGetProject.bind(ProjectsApi),
        {
          projectUuid
        }
      );

      return response ?? null;
    }),
    _loadCheckpoint: flow(function* () {
      const response: CheckpointOut = yield self.fetchCheckpointRequest.send(
        CheckpointsApi.checkpointsGetCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid ?? '',
          checkpointUuid: self.checkpointUuid
        }
      );

      return response ?? null;
    })
  }))
  .actions((self) => ({
    _setRunResults(status: CalculationTaskRunStatus | null): void {
      if (!status) {
        self.errors = cast([]);
        self.runStatus = EComputeStatusCode.Failure;
      } else {
        self.errors = cast(status.errorInfo);
        self.runStatus = status.statusCode.toString() as EComputeStatusCode;
      }
    }
  }))
  // TODO: Use sockets
  .actions((self) => ({
    _setLastSave(date: Date): void {
      self.checkpointLastSaving = date;
    },
    _clearIntervals() {
      if (self._lastSaveInterval) {
        clearInterval(self._lastSaveInterval);
      }
    },
    _startPullingLastSave(): void {
      self._lastSaveInterval = setInterval(() => {
        self._loadCheckpoint().then((checkpoint) => {
          if (checkpoint) {
            this._setLastSave(checkpoint.lastSavingDt);
          }
        });
      }, 10000);
    }
  }))
  .actions((self) => ({
    _restoreUiState() {
      const storageValue = self._uiStateStorage.get();
      if (storageValue) {
        applySnapshot(self.uiState, storageValue);
      }
    },
    _saveUiState() {
      self._uiStateStorage.store(getSnapshot(self.uiState));
    },
    selectTabIndex(index: number) {
      self.uiState._setTabIndex(index);
      this._saveUiState();
    }
  }))
  .actions((self) => ({
    refreshProject: flow(function* () {
      if (self.projectInfo?.uuid) {
        const project: ProjectOut | null = yield self._load(self.projectInfo?.uuid);
        if (project) {
          self.projectInfo = project;
        }
      }
    }),
    _baseInit: flow(function* (projectId: string, checkpointId: string) {
      const project: ProjectOut | null = yield self._load(projectId);
      const checkpoint = project?.checkpoints.find((c) => c.uuid === checkpointId);

      if (project && checkpoint) {
        self.projectInfo = project;
        self.checkpointUuid = checkpoint.uuid;
        self.checkpointLastSaving = checkpoint.lastSavingDt;

        self._restoreUiState();
        self._startPullingLastSave();
      }
    })
  }))
  .actions((self) => ({
    createCheckpoint: flow(function* () {
      const response: ResponseStatusCheckpointOut = yield self.checkpointCreateRequest.send(
        CheckpointsApi.checkpointsCreateCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid ?? '',
          fromCheckpoint: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return response?.data?.uuid ?? '';
    }),
    renameCheckpoint: flow(function* (uuid: string, name: string) {
      if (self.projectInfo) {
        self.projectInfo = {
          ...self.projectInfo,
          checkpoints: self.projectInfo.checkpoints.map((c) => ({
            ...c,
            name: c.uuid === uuid ? name : c.name
          }))
        };

        yield self.checkpointChangeRequest.send(
          CheckpointsApi.checkpointsUpdateCheckpoint.bind(CheckpointsApi),
          {
            projectUuid: self.projectInfo?.uuid ?? '',
            checkpointUuid: uuid,
            checkpointInUpdate: {
              name
            }
          }
        );
      }
    }),
    deleteCheckpoint: flow(function* (uuid: string) {
      yield self.checkpointDeleteRequest.send(
        CheckpointsApi.checkpointsDeleteCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid ?? '',
          checkpointUuid: uuid
        }
      );

      return self.checkpointDeleteRequest.isDone;
    })
  }))
  .views((self) => ({
    get isCheckpointCreating(): boolean {
      return self.checkpointCreateRequest.isPending;
    },
    get isCheckpointDeleting(): boolean {
      return self.checkpointDeleteRequest.isPending;
    }
  }))
  .views((self) => ({
    get projectUuid(): string {
      return self.projectInfo?.uuid ?? '';
    },
    get checkpoints(): Array<CheckpointOut> {
      return self.projectInfo?.checkpoints ?? [];
    }
  }));

export type TProjectBaseModel = Instance<typeof ProjectBase>;

export {ProjectBase};
