import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Flex, Text} from '@chakra-ui/react';
import {getDateTimeString} from '@progress-fe/core';

import {ECalculationStatus} from 'core/enums';
import {IJournalPoint} from 'core/interfaces';

interface IProps {
  points: IJournalPoint[];
  activePoint: Date;
  onSelectPoint: (point: Date) => void;
}

const JournalPointListFC: FC<IProps> = ({points, activePoint, onSelectPoint}) => {
  const getColor = useCallback((point: IJournalPoint) => {
    switch (point.status) {
      case ECalculationStatus.CalcResultSuccess:
        return 'success';
      case ECalculationStatus.CalcResultPartialSuccess:
        return 'darkOrange';
      case ECalculationStatus.CalcResultError:
        return 'darkRed';
      default:
        return 'black';
    }
  }, []);

  const hasWarningDot = useCallback((point: IJournalPoint) => {
    return point.status === ECalculationStatus.CalcResultSuccess && point.isWarning;
  }, []);

  return (
    <Box>
      {points.map((point) => (
        <Flex
          gap={'4px'}
          p={'2px 4px'}
          alignItems={'center'}
          color={getColor(point)}
          key={point.calculationNumber}
          bg={activePoint === point.runAt ? 'buttonBg' : 'transparent'}
          _hover={{bg: 'buttonBg', cursor: 'pointer'}}
          onClick={() => onSelectPoint(point.runAt)}
        >
          {hasWarningDot(point) ? (
            <Box width={'6px'} height={'6px'} bg={'darkOrange'} borderRadius={'50%'} />
          ) : (
            <Box width={'6px'} />
          )}
          <Text as={'span'} fontSize={'12px'} fontWeight={600} color={'inherit'}>
            №{point.calculationNumber}:{' '}
          </Text>
          {getDateTimeString(point.runAt)}
        </Flex>
      ))}
    </Box>
  );
};

export const JournalPointList = observer(JournalPointListFC);
