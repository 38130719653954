import {cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';

import {TechProcessApi} from 'api';
import {RequestModel, ResetModel} from 'core/models';

const ProjectSettings = types
  .compose(
    ResetModel,
    types.model('ProjectSettings', {
      favoritesComponents: types.optional(types.array(types.string), []),
      actionRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _loadFavorites: flow(function* (projectId: string) {
      const favorites: Array<string> = yield self.actionRequest.send(
        TechProcessApi.techProcessGetFavoritePureComponents.bind(TechProcessApi),
        {
          projectUuid: projectId
        }
      );
      self.favoritesComponents = cast(favorites);
    }),
    _syncFavorites: flow(function* (projectId: string) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessSetFavoritePureComponents.bind(TechProcessApi),
        {
          projectUuid: projectId,
          requestBody: getSnapshot(self.favoritesComponents)
        }
      );
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectId: string) {
      yield self._loadFavorites(projectId);
    }),
    addToFavorites(projectId: string, componentsIds: string[]) {
      self.favoritesComponents.push(...componentsIds);
      self._syncFavorites(projectId).then();
    },
    removeFromFavorites(projectId: string, componentsIds: string[]) {
      const removeSet = new Set(componentsIds);
      const filtered = self.favoritesComponents.filter((item) => !removeSet.has(item));
      self.favoritesComponents = cast(filtered);
      self._syncFavorites(projectId).then();
    }
  }))
  .views((self) => ({
    get favorites(): string[] {
      return self.favoritesComponents;
    }
  }));

export type TProjectSettingsModel = Instance<typeof ProjectSettings>;

export {ProjectSettings};
