import {FC} from 'react';
import {Box, Center, Text} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {useT} from '@progress-fe/core';
import {ElementIcon} from '@progress-fe/rf-core';
import {ItemListHeader, ISvgMenuItem, Loader, useDialog} from '@progress-fe/ui-kit';

import {StructureItem} from 'ui-kit';
import {EActionType} from 'core/enums';
import {TModelDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  selectedId: string | null;
  models: TModelDetailsModel[];
  menuItems: ISvgMenuItem<string>[];
  onSelect: (modelUuid: string) => void;
  onCreate: (modelUuid: string) => Promise<void>;
  onDelete: (modelUuid: string) => Promise<void>;
  onGetElementsByModel: (modelUuid: string) => Promise<Array<string>>;
}

const ModelsListFC: FC<IProps> = ({
  isLoading,
  models,
  selectedId,
  menuItems,
  onSelect,
  onCreate,
  onDelete,
  onGetElementsByModel
}) => {
  const {t} = useT();

  const DeleteDialog = useDialog({title: t('models.delete')});

  const modelMenuItems: ISvgMenuItem<EActionType>[] = [
    {id: EActionType.Download, name: t('actions.download'), isDisabled: true},
    {id: EActionType.Duplicate, name: t('actions.duplicate'), isDisabled: true},
    {id: EActionType.Delete, name: t('actions.delete')}
  ];

  const handleOnMenuAction = async (uuid: string, action: EActionType) => {
    const model = models.find((el) => el.uuid === uuid);
    switch (action) {
      case EActionType.Delete: {
        const namesList = await onGetElementsByModel(uuid);
        if (namesList.length === 0) {
          DeleteDialog.open(t('models.deleteSureMsg', {name: model?.name}), {
            apply: {title: t('actions.delete'), isDanger: true, onClick: () => onDelete(uuid)},
            close: {title: t('actions.cancel')}
          });
        } else {
          const namesStr = namesList.join(', ');
          DeleteDialog.open(t('models.deleteSureMsgError', {name: model?.name, list: namesStr}), {
            close: {title: t('actions.close')}
          });
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Box overflowY="hidden">
      <DeleteDialog.render />
      <ItemListHeader title={t('models.models')} menuItems={menuItems} onMenuSelect={onCreate} />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {models.map((m) => (
              <StructureItem
                key={m.uuid}
                item={{
                  uuid: m.uuid,
                  name: m.name,
                  elementIcon: m.type ? ElementIcon[m.type] : undefined,
                  isActive: selectedId === m.uuid,
                  isDeletable: m.deletable
                }}
                menuItems={modelMenuItems}
                onMenuAction={(action) => handleOnMenuAction(m.uuid, action)}
                onClick={onSelect}
              />
            ))}

            {models.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{t('models.noModels')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const ModelsList = observer(ModelsListFC);
