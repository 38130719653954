import {cast, flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {EWidgetType} from 'core/enums';
import {TechProcessApi, TechprocessCalculationResult} from 'api';
import {
  ResetModel,
  RequestModel,
  ElementResultDetails,
  TElementResultDetailsModel
} from 'core/models';

const ProjectResults = types
  .compose(
    ResetModel,
    types.model('ProjectResults', {
      projectUuid: '',
      checkpointUuid: '',
      elementResults: types.optional(types.array(ElementResultDetails), []),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.elementResults.forEach((result) => {
        result.clearJsonSchemas();
      });
    },
    async loadJsonSchemasByUuid(uuid: string) {
      const result = self.elementResults.find((e) => e.uuid === uuid);
      await result?.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
    }
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      self.elementResults = cast([]);

      const response: TechprocessCalculationResult[] = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationResults.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response?.length) {
        self.elementResults = cast(
          response.map((result) => ({
            uuid: result.uuid,
            name: result.name,
            deletable: false,
            lastUpdated: new Date(),
            elementUuid: result.elementUuid,
            elementType: result.elementType as EElement,
            widget: {
              uuid: result.widget.uuid,
              name: result.widget.name,
              data: result.widget.data,
              widgetType: result.widget.widgetType as EWidgetType
            }
          }))
        );
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    hasElementResult(uuid: string) {
      return self.elementResults.some((r) => r.uuid === uuid);
    },
    findElementResult(uuid: string): TElementResultDetailsModel | undefined {
      return self.elementResults.find((e) => e.uuid === uuid);
    }
  }));

export type TProjectResultsModel = Instance<typeof ProjectResults>;

export {ProjectResults};
