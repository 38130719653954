export enum EProjectSortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum EProjectSortField {
  Name = 'name',
  Type = 'type',
  Deleted = 'deleted',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}

// TODO: Join ComputeStatusCodeEnum & CalculationStatusEnum on be side

// It is used by run project EP
// Enum equals to ComputeStatusCodeEnum
export enum EComputeStatusCode {
  Success = '0',
  PartialSuccess = '1',
  Failure = '2',
  Unknown = '11184809'
}

// It is used by log viewer
// Enum equals to CalculationStatusEnum
export enum ECalculationStatus {
  NoCalcResult = 'NO_CALC_RESULT',
  CalcInProgress = 'CALC_IN_PROGRESS',
  CalcResultSuccess = 'CALC_RESULT_SUCCESS',
  CalcResultError = 'CALC_RESULT_ERROR',
  CalcResultPartialSuccess = 'CALC_RESULT_PARTIAL_SUCCESS',
  UnknownDefaultOpenApi = '11184809'
}
