import {EElement} from '@progress-fe/core';

import {TElementDetailsSnapshotIn} from 'core/models';

export const ELEMENTS_SAMPLE_ELEMENTS_LIST: {
  projectId: string;
  projectName: string;
  items: TElementDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000000',
    projectName: 'Элементы (моковый проект)',
    items: [
      {
        uuid: '196C89E0-1111-0000-0000-000000000008',
        type: EElement.CatReformingElement,
        name: 'CR-100-1',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000009',
        type: EElement.CatReformingElement,
        name: 'CR-100-2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000010',
        type: EElement.CatReformingElement,
        name: 'CR-100-3',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000011',
        type: EElement.CatReformingElement,
        name: 'CR-100-4',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000012',
        type: EElement.CatReformingElement,
        name: 'CR-100-5',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000013',
        type: EElement.CatReformingElement,
        name: 'CR-100-6',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000014',
        type: EElement.CatReformingReactorBlockElement,
        name: 'CR-RR-100',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000015',
        type: EElement.DistillationColumnWithReboilerAndCapacitorElement,
        name: 'DC-RC-100-1',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000016',
        type: EElement.DistillationColumnWithReboilerAndCapacitorElement,
        name: 'DC-RC-100-2',
        lastUpdated: new Date(),
        jsonSchemas: []
      },
      {
        uuid: '196C89E0-1111-0000-0000-000000000017',
        type: EElement.DistillationColumnWithReboilerAndCapacitorElement,
        name: 'DC-RC-100-3',
        lastUpdated: new Date(),
        jsonSchemas: []
      }
    ]
  }
];
