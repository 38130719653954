import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ItemListHeader} from '@progress-fe/ui-kit';

import {StructureItem} from 'ui-kit';
import {TElementResultDetailsModel} from 'core/models';

import {EWidgetType} from '../../../../../../core/enums';

interface IProps {
  selectedId: string | null;
  elementResults: TElementResultDetailsModel[];
  onSelect: (resultId: string) => void;
}

const ResultsListFC: FC<IProps> = ({selectedId, elementResults, onSelect}) => {
  return (
    <Box overflowY="hidden">
      <ItemListHeader title="Результаты" />
      <Box overflowY="auto" h="calc(100% - 40px)">
        {elementResults.map((el) => {
          return (
            <StructureItem
              key={el.uuid}
              item={{
                uuid: el.uuid,
                name: el.name,
                isDeletable: el.deletable,
                isActive: selectedId === el.uuid,
                icon: el.widget?.widgetType === EWidgetType.ComplexTable ? 'Table' : 'Plot'
              }}
              onClick={onSelect}
            />
          );
        })}

        {elementResults.length === 0 && (
          <Center pb="40px" height="100%">
            <Text>Нет результатов</Text>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export const ResultsList = observer(ResultsListFC);
