import {flow, Instance, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {IWidgetData} from 'core/interfaces';
import {EntityDetails, Widget} from 'core/models';
import {RJSFSchemas, TechProcessApi} from 'api';

const ElementResultDetails = types
  .compose(
    EntityDetails,
    types.model('ElementResultDetails', {
      elementUuid: types.string,
      elementType: types.enumeration(Object.values(EElement)),
      widget: Widget
    })
  )
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (self.jsonSchemasRequest.isPending) {
        return;
      }

      const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationResultSchemas.bind(TechProcessApi),
        {
          calculationResultUuid: self.uuid,
          projectUuid: projectUuid,
          checkpointUuid: checkpointUuid
        }
      );

      self.setJsonSchemas(response);
    })
  }))
  .views((self) => ({
    get widgetData(): IWidgetData {
      return {
        uuid: self.widget.uuid,
        name: self.widget.name,
        data: self.widget.data,
        type: self.widget.widgetType,
        entityUuid: self.uuid
      };
    }
  }));

export type TElementResultDetailsModel = Instance<typeof ElementResultDetails>;

export {ElementResultDetails};
