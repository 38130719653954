import {applySnapshot, cast, Instance, SnapshotIn, types} from 'mobx-state-tree';
import {EElement} from '@progress-fe/core';

import {AdditionalTabRow, RJSFSchemas} from 'api';
import {RequestModel} from 'core/models/base/RequestModel';
import {JsonSchema, TJsonSchemaSnapshotIn} from 'core/models';
import {AdditionalTab, TAdditionalTabSnapshotIn} from 'core/models/base/AdditionalTab';

const EntityDetails = types
  .model('EntityDetails', {
    uuid: types.string,
    name: types.string,
    lastUpdated: types.Date,
    deletable: types.maybe(types.boolean),
    type: types.maybeNull(types.enumeration(Object.values(EElement))),
    jsonSchemas: types.optional(types.array(JsonSchema), []),
    additionalTabs: types.optional(types.array(AdditionalTab), []),
    jsonSchemasRequest: types.optional(RequestModel, {})
  })
  .actions((self) => ({
    setJsonSchemas(inputJsonSchemas: {[key: string]: RJSFSchemas}) {
      const jsonSchemas: TJsonSchemaSnapshotIn[] = [];
      for (const [tabKey, tabSchema] of Object.entries(inputJsonSchemas)) {
        jsonSchemas.push({
          id: tabKey,
          name: tabSchema.tabName,
          tabId: tabSchema.tabId ?? undefined,
          uiSchema: tabSchema.ui,
          schema: tabSchema.jsonSchema,
          formData: tabSchema.data
        });
      }

      applySnapshot(self.jsonSchemas, jsonSchemas);
      self.lastUpdated = new Date();
    },
    setAdditionalTabs(inputTabs: Array<AdditionalTabRow>) {
      const additionalTabs: TAdditionalTabSnapshotIn[] = inputTabs.map((tab) => ({
        name: tab.name,
        uniqueCode: tab.uniqueCode,
        isActive: tab.isActive
      }));

      applySnapshot(self.additionalTabs, additionalTabs);
    },
    toggleAdditionalTab(uniqueCode: string) {
      self.additionalTabs = cast(
        self.additionalTabs.map((tab) => ({
          ...tab,
          isActive: tab.uniqueCode === uniqueCode ? !tab.isActive : tab.isActive
        }))
      );
    }
  }))
  .actions((self) => ({
    setName(name: string): void {
      self.name = name;
    },
    clearJsonSchemas() {
      self.jsonSchemas = cast([]);
      self.additionalTabs = cast([]);
    }
  }))
  .views((self) => ({
    get isSchemasLoading(): boolean {
      return self.jsonSchemasRequest.isPending;
    }
  }));

export type TEntityDetailsModel = Instance<typeof EntityDetails>;
export type TEntityDetailsSnapshotIn = SnapshotIn<typeof EntityDetails>;

export {EntityDetails};
