import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Text} from '@chakra-ui/react';
import {ItemListHeader, ISvgMenuItem, Loader, useDialog} from '@progress-fe/ui-kit';
import {useT} from '@progress-fe/core';

import {StructureItem} from 'ui-kit';
import {EActionType} from 'core/enums';
import {TSampleDetailsModel} from 'core/models';

interface IProps {
  isLoading: boolean;
  isCreating: boolean;
  selectedId: string | null;
  samples: TSampleDetailsModel[];
  onSelect: (uuid: string) => void;
  onCreate: () => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
}

const SamplesListFC: FC<IProps> = ({
  isLoading,
  isCreating,
  samples,
  selectedId,
  onSelect,
  onCreate,
  onDelete
}) => {
  const {t} = useT();

  const DeleteDialog = useDialog({title: t('samples.delete')});

  const modelMenuItems: ISvgMenuItem<EActionType>[] = [
    {id: EActionType.Download, name: t('actions.download'), isDisabled: true},
    {id: EActionType.Duplicate, name: t('actions.duplicate'), isDisabled: true},
    {id: EActionType.Delete, name: t('actions.delete')}
  ];

  const handleOnMenuAction = (uuid: string, action: EActionType) => {
    const sample = samples.find((el) => el.uuid === uuid);
    switch (action) {
      case EActionType.Delete:
        DeleteDialog.open(t('samples.deleteSureMsg', {name: sample?.name}), {
          apply: {title: t('actions.delete'), isDanger: true, onClick: () => onDelete(uuid)},
          close: {title: t('actions.cancel')}
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box overflowY="hidden">
      <DeleteDialog.render />
      <ItemListHeader
        title={t('samples.samples')}
        isMenuDisabled={isCreating}
        onCreate={onCreate}
      />

      <Box overflowY="auto" h="calc(100% - 40px)">
        {isLoading ? (
          <Center pb="40px" height="100%">
            <Loader />
          </Center>
        ) : (
          <>
            {samples.map((m) => (
              <StructureItem
                key={m.uuid}
                menuItems={modelMenuItems}
                item={{
                  uuid: m.uuid,
                  name: m.name,
                  icon: 'Sample',
                  isDeletable: m.deletable,
                  isActive: selectedId === m.uuid
                }}
                onMenuAction={(action) => handleOnMenuAction(m.uuid, action)}
                onClick={onSelect}
              />
            ))}

            {samples.length === 0 && (
              <Center pb="40px" height="100%">
                <Text>{t('samples.noSamples')}</Text>
              </Center>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export const SamplesList = observer(SamplesListFC);
