import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {BaseJsForms, ErrorBoundary} from 'ui-kit';

import {TaskForm, BackToEntity, SettingsForms} from './components';

const ProjectFormsFC: FC = () => {
  const {techProcessStore} = useStore();

  const {selectedElement, selectedSubElement, selectedLogicalElement} = techProcessStore;
  const {projectTask, projectElements, backToEntity, uiState} = techProcessStore;
  const {selectedModel, selectedResult, selectedReaction} = techProcessStore;
  const {entityType, subEntityId} = uiState;

  const {t} = useT();

  const selectedEntity = selectedElement || selectedModel || selectedResult || selectedReaction;

  return (
    <ErrorBoundary msg={t('common.jsFormError')}>
      <Box p="12px 8px" position="relative" h="100%">
        {/* Back link to an entity from a sub entity */}
        {!!selectedEntity && !!subEntityId && (
          <BackToEntity name={selectedEntity.name} onBack={backToEntity} />
        )}

        {/* Settings forms */}
        {entityType === EStructureItem.Settings && <SettingsForms />}

        {/* Element forms */}
        {entityType === EStructureItem.Element && !!selectedElement && !selectedSubElement && (
          <BaseJsForms
            key={selectedElement.uuid}
            uuid={selectedElement.uuid}
            name={selectedElement.name}
            jsonSchemas={selectedElement.jsonSchemas}
            additionalTabs={selectedElement.additionalTabs}
            isLoading={selectedElement.isSchemasLoading || projectElements.isTabsChanging}
            onUpdateFormData={techProcessStore.updateElementFormData}
            onToggleTab={(code) => projectElements.toggleElementTab(selectedElement.uuid, code)}
          />
        )}

        {/* Sub element forms */}
        {entityType === EStructureItem.Element && !!selectedElement && !!selectedSubElement && (
          <BaseJsForms
            key={selectedSubElement.uuid}
            uuid={selectedSubElement.uuid}
            name={selectedSubElement.name}
            jsonSchemas={selectedSubElement.jsonSchemas}
            additionalTabs={selectedSubElement.additionalTabs}
            isLoading={selectedSubElement.isSchemasLoading}
            onUpdateFormData={async (subUuid, jsonSchema, formData) => {
              const uuid = selectedElement.uuid;
              await techProcessStore.updateSubElementFormData(uuid, subUuid, jsonSchema, formData);
            }}
          />
        )}

        {/* Model forms */}
        {entityType === EStructureItem.Model && !!selectedModel && (
          <BaseJsForms
            key={selectedModel.uuid}
            uuid={selectedModel.uuid}
            name={selectedModel.name}
            jsonSchemas={selectedModel.jsonSchemas}
            additionalTabs={selectedModel.additionalTabs}
            isLoading={selectedModel.isSchemasLoading}
            onUpdateFormData={techProcessStore.updateModelFormData}
          />
        )}

        {/* Reaction forms */}
        {entityType === EStructureItem.Reaction && !!selectedReaction && (
          <BaseJsForms
            key={selectedReaction.uuid}
            uuid={selectedReaction.uuid}
            name={selectedReaction.name}
            jsonSchemas={selectedReaction.jsonSchemas}
            additionalTabs={selectedReaction.additionalTabs}
            isLoading={selectedReaction.isSchemasLoading}
            onUpdateFormData={() => Promise.resolve()}
          />
        )}

        {/* Task form */}
        {entityType === EStructureItem.Task && !selectedLogicalElement && (
          <TaskForm taskInstance={projectTask} />
        )}

        {/* Logical element forms */}
        {entityType === EStructureItem.Task && !!selectedLogicalElement && (
          <BaseJsForms
            key={selectedLogicalElement.uuid}
            uuid={selectedLogicalElement.uuid}
            name={selectedLogicalElement.name}
            jsonSchemas={selectedLogicalElement.jsonSchemas}
            additionalTabs={selectedLogicalElement.additionalTabs}
            isLoading={selectedLogicalElement.isSchemasLoading}
            onUpdateFormData={techProcessStore.updateLogicalElementFormData}
          />
        )}

        {/* Result forms */}
        {entityType === EStructureItem.Result && !!selectedResult && (
          <BaseJsForms
            key={selectedResult.uuid}
            uuid={selectedResult.uuid}
            name={selectedResult.name}
            jsonSchemas={selectedResult.jsonSchemas}
            additionalTabs={selectedResult.additionalTabs}
            isLoading={selectedResult.isSchemasLoading}
            onUpdateFormData={() => Promise.resolve()}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
