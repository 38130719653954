import {cast, flow, Instance, types} from 'mobx-state-tree';
import {v4 as uuidv4} from 'uuid';

import {ResetModel, RequestModel, BlendDetails, TBlendDetailsModel} from 'core/models';
import {AppProjectsOilSchemasSchemasUpdateOut, BlendOut, BlendsOut, OilApi} from 'api';

const ProjectBlends = types
  .compose(
    ResetModel,
    types.model('ProjectBlends', {
      projectUuid: '',
      checkpointUuid: '',
      blends: types.optional(types.array(BlendDetails), []),
      formDataRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      creationRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.blends.forEach((blend) => {
        blend.clearJsonSchemas();
      });
    },
    async loadJsonSchemasByUuid(uuid: string) {
      const blend = self.blends.find((e) => e.uuid === uuid);
      await blend?.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
    }
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      self.blends = cast([]);

      const response: BlendsOut = yield self.fetchRequest.send(OilApi.oilGetBlends.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid
      });

      if (response?.blends) {
        self.blends = cast(
          response.blends.map((m) => ({
            uuid: m.uuid,
            name: m.name,
            lastUpdated: new Date(),
            deletable: true
          }))
        );
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    addBlend: flow(function* () {
      const response: BlendOut = yield self.creationRequest.send(
        OilApi.oilCreateBlend.bind(OilApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return self.creationRequest.isDone && !!response
        ? BlendDetails.create({
            uuid: response.uuid,
            name: response.name,
            lastUpdated: new Date(),
            deletable: true
          })
        : null;
    }),
    removeBlend: flow(function* (uuid: string) {
      yield self.actionRequest.send(OilApi.oilDeleteBlend.bind(OilApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        blendUuid: uuid
      });

      return self.actionRequest.isDone;
    }),
    _updateBlendName(uuid: string, name: string) {
      const blend = self.blends.find((n) => n.uuid === uuid);
      blend?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateBlendFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      const blend = self.blends.find((el) => el.uuid === uuid);
      const jsonSchema = blend?.jsonSchemas.find((js) => js.id === schemaId);

      if (blend && jsonSchema) {
        jsonSchema.updateFormData(data);

        const response: AppProjectsOilSchemasSchemasUpdateOut = yield self.formDataRequest.send(
          OilApi.oilUpdateBlend.bind(OilApi),
          {
            blendUuid: uuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (response.name) {
            console.info('Blend name was changed.');
            self._updateSampleName(uuid, response.name);
          }
          if (response.schemas) {
            console.info('Blend schemas was changed.');
            blend.setJsonSchemas(response.schemas);
          }
        }
      }
    })
  }))
  .actions((self) => ({
    hasBlend(uuid: string): boolean {
      return self.blends.some((e) => e.uuid === uuid);
    },
    findBlend(uuid: string): TBlendDetailsModel | undefined {
      return self.blends.find((e) => e.uuid === uuid);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isCreating(): boolean {
      return self.creationRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending;
    }
  }));

export type TProjectBlendModel = Instance<typeof ProjectBlends>;

export {ProjectBlends};
