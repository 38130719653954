/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementEdgeProperties} from './ElementEdgeProperties';
import {
  ElementEdgePropertiesFromJSON,
  ElementEdgePropertiesFromJSONTyped,
  ElementEdgePropertiesToJSON
} from './ElementEdgeProperties';
import type {Target} from './Target';
import {TargetFromJSON, TargetFromJSONTyped, TargetToJSON} from './Target';
import type {EnergyFlowEdgeProperties} from './EnergyFlowEdgeProperties';
import {
  EnergyFlowEdgePropertiesFromJSON,
  EnergyFlowEdgePropertiesFromJSONTyped,
  EnergyFlowEdgePropertiesToJSON
} from './EnergyFlowEdgeProperties';
import type {MaterialFlowEdgeProperties} from './MaterialFlowEdgeProperties';
import {
  MaterialFlowEdgePropertiesFromJSON,
  MaterialFlowEdgePropertiesFromJSONTyped,
  MaterialFlowEdgePropertiesToJSON
} from './MaterialFlowEdgeProperties';
import type {Source} from './Source';
import {SourceFromJSON, SourceFromJSONTyped, SourceToJSON} from './Source';

/**
 *
 * @export
 * @interface GraphZoneEdgesInner
 */
export interface GraphZoneEdgesInner {
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  id: string;
  /**
   *
   * @type {Source}
   * @memberof GraphZoneEdgesInner
   */
  source: Source;
  /**
   *
   * @type {Target}
   * @memberof GraphZoneEdgesInner
   */
  target: Target;
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  sourceHandle?: string;
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  targetHandle?: string;
  /**
   *
   * @type {boolean}
   * @memberof GraphZoneEdgesInner
   */
  reconnectable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GraphZoneEdgesInner
   */
  deletable?: boolean;
  /**
   *
   * @type {object}
   * @memberof GraphZoneEdgesInner
   */
  style?: object;
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  sourceHandleQualifier?: string;
  /**
   *
   * @type {string}
   * @memberof GraphZoneEdgesInner
   */
  targetHandleQualifier?: string;
}

/**
 * Check if a given object implements the GraphZoneEdgesInner interface.
 */
export function instanceOfGraphZoneEdgesInner(value: object): value is GraphZoneEdgesInner {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('source' in value) || value['source'] === undefined) return false;
  if (!('target' in value) || value['target'] === undefined) return false;
  return true;
}

export function GraphZoneEdgesInnerFromJSON(json: any): GraphZoneEdgesInner {
  return GraphZoneEdgesInnerFromJSONTyped(json, false);
}

export function GraphZoneEdgesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GraphZoneEdgesInner {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    source: SourceFromJSON(json['source']),
    target: TargetFromJSON(json['target']),
    type: json['type'] == null ? undefined : json['type'],
    sourceHandle: json['sourceHandle'] == null ? undefined : json['sourceHandle'],
    targetHandle: json['targetHandle'] == null ? undefined : json['targetHandle'],
    reconnectable: json['reconnectable'] == null ? undefined : json['reconnectable'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    style: json['style'] == null ? undefined : json['style'],
    sourceHandleQualifier:
      json['sourceHandleQualifier'] == null ? undefined : json['sourceHandleQualifier'],
    targetHandleQualifier:
      json['targetHandleQualifier'] == null ? undefined : json['targetHandleQualifier']
  };
}

export function GraphZoneEdgesInnerToJSON(value?: GraphZoneEdgesInner | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    source: SourceToJSON(value['source']),
    target: TargetToJSON(value['target']),
    type: value['type'],
    sourceHandle: value['sourceHandle'],
    targetHandle: value['targetHandle'],
    reconnectable: value['reconnectable'],
    deletable: value['deletable'],
    style: value['style'],
    sourceHandleQualifier: value['sourceHandleQualifier'],
    targetHandleQualifier: value['targetHandleQualifier']
  };
}
