import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IRFNodePort, TRFWorkZone} from '@progress-fe/rf-core';
import {EElement} from '@progress-fe/core';
import {XYPosition} from '@xyflow/react';
import {v4 as uuidv4} from 'uuid';

import {IEntityToRefresh} from 'core/interfaces';
import {
  WorkZone,
  ElementsOut,
  TechProcessApi,
  ElementClassName,
  ElementUpdateOut,
  CreateElementActionResult,
  type RJSFSchemas
} from 'api';
import {
  Workzone,
  ResetModel,
  RequestModel,
  ElementDetails,
  TEntityDetailsModel,
  TElementDetailsModel
} from 'core/models';
import {
  ELEMENTS_LIST,
  WORKZONES_LIST,
  SUB_WORKZONES_LIST
} from 'core/mocks/projects/projects.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      projectUuid: '',
      checkpointUuid: '',

      elements: types.optional(types.array(ElementDetails), []),
      workzone: types.optional(Workzone, {}),
      subWorkzone: types.optional(Workzone, {}),
      subWorkzoneUuid: types.maybeNull(types.string),

      formDataRequest: types.optional(RequestModel, {}),
      subFormDataRequest: types.optional(RequestModel, {}),
      positionRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {}),
      fetchWorkzoneRequest: types.optional(RequestModel, {}),
      fetchSubWorkzoneRequest: types.optional(RequestModel, {}),
      changeTabRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    hasElement(uuid: string): boolean {
      return self.elements.some((e) => e.uuid === uuid);
    },
    findElement(uuid: string): TElementDetailsModel | undefined {
      return self.elements.find((e) => e.uuid === uuid);
    },
    findSubElement(uuid: string, subUuid: string): TEntityDetailsModel | undefined {
      const element = self.elements.find((e) => e.uuid === uuid);
      return element?.subElements.find((e) => e.uuid === subUuid);
    }
  }))
  .actions((self) => ({
    clearJsonSchemas() {
      self.elements.forEach((element) => {
        element.clearJsonSchemas();
        element.subElements.forEach((subElement) => {
          subElement.clearJsonSchemas();
        });
      });
    },
    async loadJsonSchemasByUuid(uuid: string, subUuid?: string | null) {
      const element = self.findElement(uuid);

      if (element && !subUuid) {
        await element.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
      }

      if (element && subUuid) {
        await element.loadSubJsonSchemas(self.projectUuid, self.checkpointUuid, subUuid);
      }
    }
  }))
  .actions((self) => ({
    _loadElements: flow(function* () {
      const mockElements = ELEMENTS_LIST.find((m) => m.projectId === self.projectUuid)?.items || [];
      if (mockElements.length) {
        self.elements = cast(mockElements);
        return;
      }

      const response: ElementsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response?.elements) {
        self.elements = cast(
          response.elements.map((el) => ({
            uuid: el.uuid,
            name: el.name,
            lastUpdated: new Date(),
            type: el.type as EElement,
            deletable: el.deletable,
            subElements:
              el.subElements?.map((sel) => ({
                uuid: sel.uuid,
                name: sel.name,
                lastUpdated: new Date(),
                type: sel.type as EElement,
                deletable: sel.deletable
              })) || []
          }))
        );
      }
    }),
    _loadWorkZone: flow(function* () {
      const mockWorkZone = WORKZONES_LIST.find((d) => d.projectId === self.projectUuid);
      if (mockWorkZone) {
        self.workzone.nodes = cast(mockWorkZone.nodes);
        self.workzone.edges = cast(mockWorkZone.edges);
        return;
      }

      const response: WorkZone = yield self.fetchWorkzoneRequest.send(
        TechProcessApi.techProcessGetWorkZone.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        const workZone = response as TRFWorkZone;
        self.workzone.nodes = cast(workZone.nodes);
        self.workzone.edges = cast(workZone.edges);
      }
    })
  }))
  .actions((self) => ({
    _loadSubWorkZone: flow(function* (elementUuid: string) {
      if (self.subWorkzoneUuid === elementUuid) return;

      const mockSubWorkZone = SUB_WORKZONES_LIST.find(
        (w) => w.projectUuid === self.projectUuid && w.elementUuid === elementUuid
      );

      if (mockSubWorkZone) {
        self.subWorkzoneUuid = elementUuid;
        self.subWorkzone.nodes = cast(mockSubWorkZone.nodes);
        self.subWorkzone.edges = cast(mockSubWorkZone.edges);
        return;
      }

      const response: WorkZone = yield self.fetchSubWorkzoneRequest.send(
        TechProcessApi.techProcessGetSubWorkZone.bind(TechProcessApi),
        {
          elementUuid: elementUuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        const workZone = response as TRFWorkZone;
        self.subWorkzoneUuid = elementUuid;
        self.subWorkzone.nodes = cast(workZone.nodes);
        self.subWorkzone.edges = cast(workZone.edges);
      }
    }),
    _clearSubWorkzone(): void {
      self.subWorkzoneUuid = null;
      self.subWorkzone.clear();
    }
  }))
  .actions((self) => ({
    _reload: flow(function* () {
      yield self._loadElements();
      yield self._loadWorkZone();
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;
      yield self._reload();
    })
  }))
  .actions((self) => ({
    add: flow(function* (elementType: EElement, position?: XYPosition) {
      const response: CreateElementActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newElement: {
            type: elementType as ElementClassName,
            position: position || {x: 0, y: 0}
          }
        }
      );

      if (self.actionRequest.isDone && !!response) {
        const newElement = ElementDetails.create({
          uuid: response.data.element.uuid,
          name: response.data.element.name,
          lastUpdated: new Date(),
          deletable: response.data.element.deletable,
          type: elementType,
          subElements:
            response.data.element.subElements?.map((sel) => ({
              uuid: sel.uuid,
              name: sel.name,
              lastUpdated: new Date(),
              type: sel.type as EElement
            })) || []
        });

        const entityToRefresh: IEntityToRefresh = {
          dataChanged: false,
          modelsChanged: response.data.modelsChanged,
          elementsChanged: response.data.elementsChanged,
          logicalElementsChanged: response.data.logicalElementsChanged
        };

        return {
          element: newElement,
          changedEntities: entityToRefresh
        };
      } else {
        return {
          element: null,
          changedEntities: null
        };
      }
    }),
    remove: flow(function* (uuid: string) {
      yield self.actionRequest.send(TechProcessApi.techProcessDeleteElement.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        elementUuid: uuid
      });

      return self.actionRequest.isDone;
    }),
    connect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(TechProcessApi.techProcessAddConnection.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        bodyTechProcessAddConnection: {
          sourceInfo: {
            elementUuid: from.uuid,
            portCode: from.port,
            portQualifier: from.qualifier
          },
          targetInfo: {
            elementUuid: to.uuid,
            portCode: to.port,
            portQualifier: to.qualifier
          }
        }
      });

      return self.actionRequest.isDone;
    }),
    disconnect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessRemoveConnection.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          bodyTechProcessRemoveConnection: {
            sourceInfo: {
              elementUuid: from.uuid,
              portCode: from.port,
              portQualifier: from.qualifier
            },
            targetInfo: {
              elementUuid: to.uuid,
              portCode: to.port,
              portQualifier: to.qualifier
            }
          }
        }
      );

      return self.actionRequest.isDone;
    })
  }))
  .actions((self) => ({
    _updateElementName(uuid: string, name: string) {
      const element = self.findElement(uuid);
      element?.setName(name);
    },
    _updateSubElementName(uuid: string, subUuid: string, name: string) {
      const subElement = self.findSubElement(uuid, subUuid);
      subElement?.setName(name);
    }
  }))
  .actions((self) => ({
    _updateElementFormData: flow(function* (uuid: string, schemaId: string, data: unknown) {
      console.info('Update element form data.');
      const element = self.elements.find((el) => el.uuid === uuid);
      const jsonSchema = element?.jsonSchemas.find((js) => js.id === schemaId);

      if (element && jsonSchema) {
        jsonSchema.updateFormData(data);

        const response: ElementUpdateOut = yield self.formDataRequest.send(
          TechProcessApi.techProcessUpdateElementInstance.bind(TechProcessApi),
          {
            elementUuid: uuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (response.name) {
            console.info('Element name was changed.');
            self._updateElementName(uuid, response.name);
          }
          if (response.schemas) {
            console.info('Element schemas was changed.');
            element.setJsonSchemas(response.schemas);
          }
          if (response.workzone?.nodes && response.workzone.edges) {
            console.info(`Workzone was changed. Nodes: ${response.workzone.nodes.length}.`);
            console.info(`Workzone was changed. Edges: ${response.workzone.edges.length}.`);
            self.workzone.setNodesAndEdges(response.workzone as TRFWorkZone);
          }

          const entityToRefresh: IEntityToRefresh = {
            dataChanged: response.dataChanged,
            modelsChanged: response.modelsChanged,
            elementsChanged: response.elementsChanged,
            logicalElementsChanged: response.logicalElementsChanged
          };

          return {
            changedEntities: entityToRefresh
          };
        }
        return {
          changedEntities: null
        };
      }
      return {
        changedEntities: null
      };
    }),
    _updateSubElementFormData: flow(function* (
      uuid: string,
      subUuid: string,
      schemaId: string,
      data: unknown
    ) {
      console.info('Update sub element form data.');
      const subElement = self.findSubElement(uuid, subUuid);
      const subJsonSchema = subElement?.jsonSchemas.find((js) => js.id === schemaId);

      if (subElement && subJsonSchema) {
        subJsonSchema.updateFormData(data);

        const response: ElementUpdateOut = yield self.subFormDataRequest.send(
          TechProcessApi.techProcessUpdateElementInstance.bind(TechProcessApi),
          {
            elementUuid: subUuid,
            body: data as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.subFormDataRequest.isDone) {
          if (response.name) {
            console.info('Sub element name was changed.');
            self._updateSubElementName(uuid, subUuid, response.name);
          }
          if (response.schemas) {
            console.info('Sub element schemas was changed.');
            subElement.setJsonSchemas(response.schemas);
          }
          if (response.workzone?.nodes && response.workzone.edges) {
            console.info(`Sub workzone was changed. Nodes: ${response.workzone.nodes.length}.`);
            console.info(`Sub workzone was changed. Edges: ${response.workzone.edges.length}.`);
            self.subWorkzone.setNodesAndEdges(response.workzone as TRFWorkZone);
          }
        }
      }
    }),
    updateNodePosition: flow(function* (uuid: string, position: XYPosition) {
      console.info('Update element position.');
      const node = self.workzone.nodes.find((n) => n.id === uuid);
      if (node) {
        self.workzone.nodes = cast([
          ...self.workzone.nodes.filter((n) => n.id !== uuid),
          {...node, position}
        ]);

        yield self.positionRequest.send(
          TechProcessApi.techProcessUpdateElementInstancePosition.bind(TechProcessApi),
          {
            elementUuid: uuid,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid,
            positionInfo: {
              position: position
            }
          }
        );
      }
    }),
    updateSubNodePosition: flow(function* (uuid: string, subUuid: string, position: XYPosition) {
      console.info('Update sub element position.');
      const subNode = self.subWorkzone.nodes.find((n) => n.id === subUuid);
      if (subNode) {
        self.subWorkzone.nodes = cast([
          ...self.subWorkzone.nodes.filter((n) => n.id !== subUuid),
          {...subNode, position}
        ]);

        yield self.positionRequest.send(
          TechProcessApi.techProcessUpdateElementInstancePosition.bind(TechProcessApi),
          {
            elementUuid: subUuid,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid,
            positionInfo: {
              position: position,
              subSchemeUuid: uuid
            }
          }
        );
      }
    })
  }))
  .actions((self) => ({
    toggleElementTab: flow(function* (elementUuid: string, tabUniqueCode: string) {
      console.info('Toggle element tab.');

      const element = self.elements.find((el) => el.uuid === elementUuid);
      element?.toggleAdditionalTab(tabUniqueCode);

      const response: {[key: string]: RJSFSchemas} = yield self.changeTabRequest.send(
        TechProcessApi.techProcessSetElementAdditionalTabs.bind(TechProcessApi),
        {
          elementUuid: elementUuid,
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          additionalTabsInfo: {
            tabs:
              element?.additionalTabs
                .filter((at) => at.isActive)
                .map((at) => ({uniqueCode: at.uniqueCode})) ?? []
          }
        }
      );

      if (self.changeTabRequest.isDone && response) {
        console.info('Element schemas was changed.');
        element?.setJsonSchemas(response);
      } else {
        element?.toggleAdditionalTab(tabUniqueCode);
      }
    })
  }))
  .views((self) => ({
    get subWorkzoneElement() {
      return self.subWorkzoneUuid ? self.findElement(self.subWorkzoneUuid) || null : null;
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isTabsChanging(): boolean {
      return self.changeTabRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending || self.subFormDataRequest.isPending;
    }
  }));

export type TProjectElementsModel = Instance<typeof ProjectElements>;

export {ProjectElements};
