import {cast, flow, types} from 'mobx-state-tree';
import {delay, EStructureItem, EventEmitter, ISampleInfo} from '@progress-fe/core';

import {ProjectBase, TEntityDetailsModel} from 'core/models';

import {ProjectSamples, ProjectBlends} from './models';

const RUN_DELAY_MS = 1000;

const SamplesAndBlendsStore = types
  .compose(
    ProjectBase,
    types.model('SamplesAndBlendsStore', {
      projectSamples: types.optional(ProjectSamples, {}),
      projectBlends: types.optional(ProjectBlends, {})
    })
  )
  .actions((self) => ({
    _clearAllJsonSchemas(): void {
      self.projectSamples.clearJsonSchemas();
      self.projectBlends.clearJsonSchemas();
    },
    // This is called during first load or after running
    _reloadActiveEntity(): void {
      this._clearAllJsonSchemas();

      const {entityId} = self.uiState;

      if (self.uiState.entityType === EStructureItem.Sample) {
        self.projectSamples.loadJsonSchemasByUuid(entityId).then();
      } else if (self.uiState.entityType === EStructureItem.Blend) {
        self.projectBlends.loadJsonSchemasByUuid(entityId).then();
      }
    },
    // This is called by a structure item
    selectEntityByType(entityType: EStructureItem, uuid?: string | null, subUuid?: string | null) {
      this._clearAllJsonSchemas();
      self.uiState.select(entityType, uuid, subUuid);

      if (entityType === EStructureItem.Sample && !!uuid) {
        self.projectSamples.loadJsonSchemasByUuid(uuid).then();
      } else if (entityType === EStructureItem.Blend && !!uuid) {
        self.projectBlends.loadJsonSchemasByUuid(uuid).then();
      }

      self.uiState._setTabIndex(0);
      self._saveUiState();
    }
  }))
  .actions(() => ({
    _selectEntity() {
      throw Error('there is no implementation');
    }
  }))
  .actions((self) => ({
    _subscribe() {
      EventEmitter.on('SelectItem', self._selectEntity);
    },
    _unsubscribe() {
      EventEmitter.off('SelectItem', self._selectEntity);
    },
    uninitialize() {
      this._unsubscribe();
      self._clearIntervals();
      self.resetModel();
    }
  }))
  .actions((self) => ({
    createSample: flow(function* () {
      const sample = yield self.projectSamples.addSample();
      if (sample) {
        self.projectSamples.samples.push(sample);
        self.selectEntityByType(EStructureItem.Sample, sample.uuid);
        return sample.uuid;
      } else {
        return null;
      }
    }),
    deleteSample: flow(function* (uuid: string) {
      const isDone = yield self.projectSamples.removeSample(uuid);
      if (isDone) {
        const {samples} = self.projectSamples;
        self.projectSamples.samples = cast([...samples.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateSampleFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectSamples._updateSampleFormData(uuid, schemaId, data);
    }
  }))
  .actions((self) => ({
    createBlend: flow(function* () {
      const blend = yield self.projectBlends.addBlend();
      if (blend) {
        self.projectBlends.blends.push(blend);
        self.selectEntityByType(EStructureItem.Blend, blend.uuid);
        return blend.uuid;
      } else {
        return null;
      }
    }),
    deleteBlend: flow(function* (uuid: string) {
      const isDone = yield self.projectBlends.removeBlend(uuid);
      if (isDone) {
        const {blends} = self.projectBlends;
        self.projectBlends.blends = cast([...blends.filter((n) => n.uuid !== uuid)]);
        self.selectEntityByType(EStructureItem.Settings);
      }
    }),
    async updateBlendFormData(uuid: string, schemaId: string, data: unknown) {
      await self.projectBlends._updateBlendFormData(uuid, schemaId, data);
    }
  }))
  .actions(() => ({
    _loadJournal: flow(function* () {
      yield Promise.resolve();
    })
  }))
  .actions((self) => ({
    _reloadProjectData: flow(function* (projectUuid: string, checkpointUuid: string) {
      yield self.projectSamples.init(projectUuid, checkpointUuid);
      yield self.projectBlends.init(projectUuid, checkpointUuid);
      yield self._loadJournal();
    })
  }))
  .actions((self) => ({
    initProject: flow(function* (projectId: string, checkpointId: string) {
      self.isLoading = true;
      yield self._baseInit(projectId, checkpointId);

      if (self.projectInfo && self.checkpointUuid) {
        yield self._reloadProjectData(self.projectInfo.uuid, self.checkpointUuid);
        self._reloadActiveEntity();
        self._subscribe();
      }

      self.isLoading = false;
    })
  }))
  .actions(() => ({
    run: flow(function* () {
      yield delay(RUN_DELAY_MS);
    })
  }))
  .views((self) => ({
    get selectedSample(): TEntityDetailsModel | null {
      return self.projectSamples.findSample(self.uiState.entityId) ?? null;
    },

    get selectedBlend(): TEntityDetailsModel | null {
      return self.projectBlends.findBlend(self.uiState.entityId) ?? null;
    }
  }))
  .views((self) => ({
    get sampleDictionary(): ISampleInfo[] {
      return self.projectSamples.samples.map((s) => ({sampleId: s.uuid, sampleName: s.name}));
    }
  }));

export {SamplesAndBlendsStore};
