/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {HandleQualifiers} from './HandleQualifiers';
import {
  HandleQualifiersFromJSON,
  HandleQualifiersFromJSONTyped,
  HandleQualifiersToJSON
} from './HandleQualifiers';
import type {LogicalElementItem} from './LogicalElementItem';
import {
  LogicalElementItemFromJSON,
  LogicalElementItemFromJSONTyped,
  LogicalElementItemToJSON
} from './LogicalElementItem';
import type {Style} from './Style';
import {StyleFromJSON, StyleFromJSONTyped, StyleToJSON} from './Style';
import type {ElementData} from './ElementData';
import {ElementDataFromJSON, ElementDataFromJSONTyped, ElementDataToJSON} from './ElementData';
import type {WorkZoneElementData} from './WorkZoneElementData';
import {
  WorkZoneElementDataFromJSON,
  WorkZoneElementDataFromJSONTyped,
  WorkZoneElementDataToJSON
} from './WorkZoneElementData';

/**
 *
 * @export
 * @interface Data2
 */
export interface Data2 {
  /**
   *
   * @type {string}
   * @memberof Data2
   */
  elementName?: string;
  /**
   *
   * @type {string}
   * @memberof Data2
   */
  templateCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof Data2
   */
  isSubElement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Data2
   */
  isNotElement?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Data2
   */
  isRotate?: boolean;
  /**
   *
   * @type {Array<LogicalElementItem>}
   * @memberof Data2
   */
  logicalElements?: Array<LogicalElementItem>;
  /**
   *
   * @type {Style}
   * @memberof Data2
   */
  style?: Style;
  /**
   *
   * @type {Array<HandleQualifiers>}
   * @memberof Data2
   */
  handleQualifiers?: Array<HandleQualifiers>;
}

/**
 * Check if a given object implements the Data2 interface.
 */
export function instanceOfData2(value: object): value is Data2 {
  return true;
}

export function Data2FromJSON(json: any): Data2 {
  return Data2FromJSONTyped(json, false);
}

export function Data2FromJSONTyped(json: any, ignoreDiscriminator: boolean): Data2 {
  if (json == null) {
    return json;
  }
  return {
    elementName: json['elementName'] == null ? undefined : json['elementName'],
    templateCode: json['templateCode'] == null ? undefined : json['templateCode'],
    isSubElement: json['isSubElement'] == null ? undefined : json['isSubElement'],
    isNotElement: json['isNotElement'] == null ? undefined : json['isNotElement'],
    isRotate: json['isRotate'] == null ? undefined : json['isRotate'],
    logicalElements:
      json['logicalElements'] == null
        ? undefined
        : (json['logicalElements'] as Array<any>).map(LogicalElementItemFromJSON),
    style: json['style'] == null ? undefined : StyleFromJSON(json['style']),
    handleQualifiers:
      json['handleQualifiers'] == null
        ? undefined
        : (json['handleQualifiers'] as Array<any>).map(HandleQualifiersFromJSON)
  };
}

export function Data2ToJSON(value?: Data2 | null): any {
  if (value == null) {
    return value;
  }
  return {
    elementName: value['elementName'],
    templateCode: value['templateCode'],
    isSubElement: value['isSubElement'],
    isNotElement: value['isNotElement'],
    isRotate: value['isRotate'],
    logicalElements:
      value['logicalElements'] == null
        ? undefined
        : (value['logicalElements'] as Array<any>).map(LogicalElementItemToJSON),
    style: StyleToJSON(value['style']),
    handleQualifiers:
      value['handleQualifiers'] == null
        ? undefined
        : (value['handleQualifiers'] as Array<any>).map(HandleQualifiersToJSON)
  };
}
