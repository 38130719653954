/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const ERFElement = {
  Element: 'element',
  FlowMaterial: 'flowMaterial',
  FlowEnergy: 'flowEnergy',
  Cooler: 'cooler',
  Heater: 'heater',
  HeatExchanger: 'heatExchanger',
  HeatExchangerSynthetic: 'heatExchangerSynthetic',
  Compressor: 'compressor',
  Valve: 'valve',
  Pump: 'pump',
  Detander: 'detander',
  WaterJetPump: 'waterJetPump',
  Mixer: 'mixer',
  Splitter: 'splitter',
  Pipeline: 'pipeline',
  Separator2ph: 'separator2ph',
  Separator3ph: 'separator3ph',
  Fractionator: 'fractionator',
  CatCracking: 'catCracking',
  CatReforming: 'catReforming',
  CatReformingRrBlock: 'catReformingRRBlock',
  ConversionReactor: 'conversionReactor',
  EquilibriumReactor: 'equilibriumReactor',
  Absorber: 'absorber',
  ComponentSplitter: 'componentSplitter',
  CatCrackingRrBlock: 'catCrackingRRBlock',
  DistillationColumnRc: 'distillationColumnRC',
  DotMaterial: 'dotMaterial',
  DotEnergy: 'dotEnergy',
  Balancer: 'balancer',
  MultiStepCoherent: 'multiStepCoherent',
  MultiStepExternal: 'multiStepExternal',
  Recycle: 'recycle',
  GroupDashed: 'groupDashed',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type ERFElement = (typeof ERFElement)[keyof typeof ERFElement];

export function instanceOfERFElement(value: any): boolean {
  for (const key in ERFElement) {
    if (Object.prototype.hasOwnProperty.call(ERFElement, key)) {
      if ((ERFElement as Record<string, ERFElement>)[key] === value) {
        return true;
      }
    }
  }
  return false;
}

export function ERFElementFromJSON(json: any): ERFElement {
  return ERFElementFromJSONTyped(json, false);
}

export function ERFElementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ERFElement {
  return json as ERFElement;
}

export function ERFElementToJSON(value?: ERFElement | null): any {
  return value as any;
}
