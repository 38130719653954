import {FC, memo, useEffect, useState} from 'react';
import {Box} from '@chakra-ui/react';
import {TRFSvgName} from '@progress-fe/rf-core';
import {ISvgMenuItem, TSvgName} from '@progress-fe/ui-kit';

import {EActionType} from 'core/enums';

import {ItemOrChild} from './components';

export interface IStructureItem {
  uuid: string;
  name: string;
  suffix?: string;
  icon?: TSvgName | null;
  elementIcon?: TRFSvgName | null;
  isDeletable?: boolean;
  isActive?: boolean;
}

interface IProps {
  item: IStructureItem;
  subItems?: IStructureItem[];
  menuItems?: ISvgMenuItem<EActionType>[];
  onMenuAction?: (value: EActionType) => void;
  onClick?: (uuid: string, subUuid?: string) => void;
}

const StructureItemFC: FC<IProps> = ({item, subItems, menuItems, onClick, onMenuAction}) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasChildren = !!subItems?.length;

  useEffect(() => {
    if (subItems?.some((s) => s.isActive)) {
      setIsOpen(true);
    }
  }, [subItems]);

  return (
    <>
      <ItemOrChild
        item={item}
        menuItems={menuItems?.map((mi) => ({
          ...mi,
          isDisabled: !item.isDeletable ? true : mi.isDisabled,
          name: !item.isDeletable ? mi.disabledName || mi.name : mi.name
        }))}
        isChildShown={isOpen}
        hasChildItems={hasChildren}
        onToggleChildren={() => setIsOpen(!isOpen)}
        onMenuAction={onMenuAction}
        onClick={onClick}
      />

      {hasChildren && isOpen && (
        <Box>
          {subItems.map((subItem) => (
            <ItemOrChild
              isChild
              item={subItem}
              key={subItem.uuid}
              onClick={(subUuid) => onClick?.(item.uuid, subUuid)}
            />
          ))}
        </Box>
      )}
    </>
  );
};

export const StructureItem = memo(StructureItemFC);
