import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem, useT} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {BaseJsForms, ErrorBoundary} from 'ui-kit';

import {SettingsForms} from './components';

const ProjectFormsFC: FC = () => {
  const {samplesAndBlendsStore} = useStore();
  const {selectedSample, selectedBlend, uiState} = samplesAndBlendsStore;
  const {entityType} = uiState;

  const {t} = useT();

  return (
    <ErrorBoundary msg={t('common.jsFormError')}>
      <Box p="12px 8px" position="relative" h="100%">
        {/* SETTINGS FORMS */}
        {entityType === EStructureItem.Settings && <SettingsForms />}

        {/* SAMPLES FORMS */}
        {entityType === EStructureItem.Sample && !!selectedSample && (
          <BaseJsForms
            key={selectedSample.uuid}
            uuid={selectedSample.uuid}
            name={selectedSample.name}
            jsonSchemas={selectedSample.jsonSchemas}
            additionalTabs={selectedSample.additionalTabs}
            isLoading={selectedSample.isSchemasLoading}
            onUpdateFormData={samplesAndBlendsStore.updateSampleFormData}
          />
        )}

        {/* BLENDS FORMS */}
        {entityType === EStructureItem.Blend && !!selectedBlend && (
          <BaseJsForms
            key={selectedBlend.uuid}
            uuid={selectedBlend.uuid}
            name={selectedBlend.name}
            jsonSchemas={selectedBlend.jsonSchemas}
            additionalTabs={selectedBlend.additionalTabs}
            isLoading={selectedBlend.isSchemasLoading}
            onUpdateFormData={samplesAndBlendsStore.updateBlendFormData}
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
