/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {WidgetTypeEnum} from './WidgetTypeEnum';
import {
  WidgetTypeEnumFromJSON,
  WidgetTypeEnumFromJSONTyped,
  WidgetTypeEnumToJSON
} from './WidgetTypeEnum';

/**
 *
 * @export
 * @interface TechprocessCalculationResultWidget
 */
export interface TechprocessCalculationResultWidget {
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationResultWidget
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof TechprocessCalculationResultWidget
   */
  name: string;
  /**
   *
   * @type {WidgetTypeEnum}
   * @memberof TechprocessCalculationResultWidget
   */
  widgetType: WidgetTypeEnum;
  /**
   *
   * @type {any}
   * @memberof TechprocessCalculationResultWidget
   */
  data: any | null;
}

/**
 * Check if a given object implements the TechprocessCalculationResultWidget interface.
 */
export function instanceOfTechprocessCalculationResultWidget(
  value: object
): value is TechprocessCalculationResultWidget {
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('widgetType' in value) || value['widgetType'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  return true;
}

export function TechprocessCalculationResultWidgetFromJSON(
  json: any
): TechprocessCalculationResultWidget {
  return TechprocessCalculationResultWidgetFromJSONTyped(json, false);
}

export function TechprocessCalculationResultWidgetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TechprocessCalculationResultWidget {
  if (json == null) {
    return json;
  }
  return {
    uuid: json['uuid'],
    name: json['name'],
    widgetType: WidgetTypeEnumFromJSON(json['widget_type']),
    data: json['data']
  };
}

export function TechprocessCalculationResultWidgetToJSON(
  value?: TechprocessCalculationResultWidget | null
): any {
  if (value == null) {
    return value;
  }
  return {
    uuid: value['uuid'],
    name: value['name'],
    widget_type: WidgetTypeEnumToJSON(value['widgetType']),
    data: value['data']
  };
}
