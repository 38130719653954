import {cast, flow, Instance, SnapshotIn, types} from 'mobx-state-tree';

import {EntityDetails} from 'core/models';
import {RJSFSchemas, TechProcessApi} from 'api';
import {MODELS_LIST, PROJECT_LIST} from 'core/mocks/projects/projects.mocks';

const ModelDetails = types
  .compose(EntityDetails, types.model('ModelDetails', {}))
  .actions((self) => ({
    loadJsonSchemas: flow(function* (projectUuid: string, checkpointUuid: string) {
      if (self.jsonSchemasRequest.isPending) {
        return;
      }

      if (PROJECT_LIST.find((p) => p.uuid === projectUuid)) {
        const mockModel = MODELS_LIST.find((p) => p.projectId === projectUuid)?.items.find(
          (el) => el.uuid === self.uuid
        );
        if (mockModel) {
          self.jsonSchemas = cast(mockModel.jsonSchemas);
        }
        return;
      }

      const response: {[key: string]: RJSFSchemas} = yield self.jsonSchemasRequest.send(
        TechProcessApi.techProcessGetModelSchemas.bind(TechProcessApi),
        {
          modelInstanceUuid: self.uuid,
          projectUuid: projectUuid,
          checkpointUuid: checkpointUuid
        }
      );

      self.setJsonSchemas(response);
    })
  }));

export type TModelDetailsModel = Instance<typeof ModelDetails>;
export type TModelDetailsSnapshotIn = SnapshotIn<typeof ModelDetails>;

export {ModelDetails};
